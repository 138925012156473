.qr-code-generator {
  padding: 20px;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .input-container {
    display: flex;
    margin-bottom: 20px;

    input {
      flex-grow: 1;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px 0 0 4px;
    }

    button {
      padding: 10px 20px;
      font-size: 16px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 0 4px 4px 0;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .qrcodes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .qr-code-item {
      text-align: center;
      
      h2 {
        font-size: 18px;
        margin-bottom: 10px;
      }

      canvas {
        border: 1px solid #ccc;
        padding: 10px;
      }
    }
  }
}