/* @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Nunito', sans-serif;
}

main {
  padding: 1rem;
 }

article {
  font-size: 1.2rem;
  width: 90%;
  margin: 1rem;
}

button {
  margin: 1rem 1rem 0.5rem;
  font: inherit;
  font-size: 2rem;
  padding: 0.5em 1.5em;
  cursor: pointer;
  border-radius: 0.5rem;
}



.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
} */

/* //================================================ */

.avatar_img {
  width: 200px;
  border: 3px solid #000;
  border-radius: 50%;
  margin: 0 1rem 1rem;
}
