:root {
  --gray-light-3: #eee;
  --gray-light-2: #ddd;
  --gray-light: #ccc;
  --gray: #888;

  --radius-btn: 4px;
  --radius-defaut: 8px;
  --radius-full: 1000px;
}

.logoImg {
  background: #6fa0c3;

  width: 50px;
}
.notification {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #575757;
  border: 3px solid rgb(0, 0, 0);

  color: white;
  padding: 10px 20px;
  width: fit-content;
}

.my-dialog {
  @media (max-width: 479.98px) {
  .MuiDialog-paper {
    margin: 5px;
  }

  .MuiDialogContent-root {
    padding-left: 14px;
    padding-right: 14px;
  }
}
}

.pos-absolute {
  position: absolute;
}
.pos-relative {
  position: relative;
}

.ovh {
  overflow: hidden;
}

.center {
  text-align: center;
}

.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}

.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}

.g5 {
  gap: 5px;
}
.g10 {
  gap: 10px;
}
.g15 {
  gap: 15px;
}
.g20 {
  gap: 20px;
}

.flex-row,
.flex-rows,
.flex-column {
  display: flex;
}
.flex-rows {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}

.flex-x-center {
  justify-content: center;
}
.flex-x-space-between {
  justify-content: space-between;
}
.flex-y-center {
  align-items: center;
}

.gray {
  color: var(--gray);
}
.gray-light {
  color: var(--gray-light);
}

.gray-bg-light-3 {
  background-color: var(--gray-light-3);
}

.white-bg {
  background-color: #fff;
}


.border-light {
  border: 1px solid var(--gray-light);
}
.radius-btn {
  border-radius: var(--radius-btn);
}
.radius-default {
  border-radius: var(--radius-defaut);
}
.radius-full {
  border-radius: var(--radius-full);
}

.w240 {
  width: 240px;
}
.w200 {
  width: 200px;
}
.h200 {
  height: 200px;
}
.h160 {
  height: 160px;
}

.bg-img,
.bg-img-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.bg-img-cover {
  background-size: cover;
}

.bg-white {
  background: #fff;
}

.nowrap {
  white-space: nowrap;
}

.scrolled {
  overflow: scroll;
}
.scrolled-x {
  overflow-x: scroll;
}
.scrolled-y {
  overflow-y: scroll;
}

.scrolled-x::-webkit-scrollbar,
.scrolled-y::-webkit-scrollbar,
.scrolled::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px
}

.scrolled-x::-webkit-scrollbar-track,
.scrolled-y::-webkit-scrollbar-track,
.scrolled::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,0)
}

.scrolled-x::-webkit-scrollbar-thumb,
.scrolled-y::-webkit-scrollbar-thumb,
.scrolled::-webkit-scrollbar-thumb {
  background-color: hsla(0,0%,39.2%,.7);
  border-radius: 4px;
  border: 1px solid #fff
}

// body {
//   background-color: #1c64f6;
// }


// .broken-img {
//   font-size: 1.25em;
//   width: 40%;
//   min-width: 22em;
//   position: absolute;
//   /* Just For Centering Purpose*/
//   transform: translate(-50%, -50%);
//   left: 50%;
//   top: 50%;
// }
/* This will be display only if the image is broken */
.broken-img:before {
  position: absolute;
  content: "\01F614    Sorry! The "attr(alt) " image not found.";
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 0.9em;
  left: 0;
  top: 0;
  width: 100%;
  // padding: 1em 0;
  text-align: center;
  border: 3px dashed #afb3e5;
  box-shadow: 0 0 0 0.45em #ffffff, 0 1.2em 3.6em rgba(1, 7, 60, 0.3);
  border-radius: 0.2em;
}

.my-form {
  .MuiFormLabel-root {
    margin-bottom: 4px;
  }
}
